<template>
    <table class='table table-hover'>
        <thead class='table_head'>
            <tr>
                <th class='slug-col'>Slug</th>
                <th class='name-col'>Заголовок страницы</th>
                <th class='desc-col'>Контент превью</th>
                <th class='status-col'>Опубликовано?</th>
                <th class='lang-col'>Язык</th>
                <th class='actions-col'>Действия</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for='(article, index) in articles' :key='index'>
                <td>{{ article.slug }}</td>
                <td>{{ article.title }}</td>
                <td>{{ article.content_preview }}</td>
                <td>{{ article.isPublished }}</td>
                <td>{{ article.location }}</td>
                <td class='actions_container'>
                    <router-link class='leads_table_action_link btn mr-2 btn-primary' :to="{ name: 'BlogArticleEdit', params: {id: article.id} }">
                        <span class='glyphicon glyphicon-edit'></span>
                    </router-link>
                    <a class='btn mr-2 btn-danger' @click.prevent='deleteRow(article.id, article.slug)'>
                        <span class='fa fa-trash-o delete'></span>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import duration from 'dayjs/plugin/duration';

export default {
    name: 'BlogArticlesTable',
    props: {
        articles: {
            default: [],
        },
        deleteRow: {
            type: Function,
        },
    },
    created() {
        dayjs.extend(duration);
        dayjs.locale(ru);
    },
};
</script>

<style lang='scss' scoped>
.table_head th {
    font-size: 12px;
    text-align: center;

    &:first-child {
        text-align: left;
    }
}

.table {
    td {
        word-break: break-word;
    }
}

.slug-col,
.name-col,
.lang-col,
.desc-col {
    text-align: left !important;
}

.slug-col {
    width: 300px;
}
.status-col,
.lang-col {
    width: 100px;
}
.name-col {
    width: 350px;
}
.actions-col {
    width: 150px;
}
</style>